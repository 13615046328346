











































































































































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
  useRoute,
  ssrRef,
  computed,
  onDeactivated,
  onActivated
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { Logger } from '~/helpers/logger';
import Swal from "sweetalert2";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBanner, SfBannerGrid, SfSelect, SfHeading } from "@storefront-ui/vue";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useAddToCart } from "~/helpers/cart/addToCart";
import type { CartAddressCountry, ProductInterface } from "~/modules/GraphQL/types";
import { useContent, useUiState, useUiHelpers, useFacet, useFacetSearch, useUiNotification } from "~/composables";
import CategoryNavbar from "~/modules/catalog/category/components/navbar/CategoryNavbar.vue";
import { perPageOptions } from "~/modules/catalog/category/composables/useFacet/perPageOptions";
import { useProduct } from "~/modules/catalog/product/composables/useProduct";
import { Products, Aggregation } from "~/modules/GraphQL/types";
import type { Product } from "~/modules/catalog/product/types";
import CategoryPagination from "~/modules/catalog/category/components/pagination/CategoryPagination.vue";
import type { Pagination } from "~/composables/types";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { sortingOptions,SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import type { UseFacetSearchResult } from "~/modules/catalog/category/composables/useFacet";
import useApi from '~/composables/useApi';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import DYDataHandler from "~/components/FortyTwo/homepage/DYRecommendation/DYDataHandler.vue";
import DYProductWithEmpty from "~/components/FortyTwo/homepage/DYRecommendation/DYProductWithEmpty.vue";
import SearchWithEmpty from "~/pages/SearchWithEmpty.vue";
import GA4AddRemoveCart from "~/modules/fortytwo/googleanalytics4/components/GA4AddRemoveCart.vue";
import GA4SelectItem from "~/modules/fortytwo/googleanalytics4/components/GA4SelectItem.vue";
import { useActiveCategoryStore } from "~/modules/fortytwo/category/stores/activecategory";
import { useCart } from "~/modules/checkout/composables/useCart";
import CustomHead from '~/dy/customHead.vue';
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';
import GetProductFilterBySearchQuery from '~/modules/catalog/category/components/filters/command/getProductFilterBySearch.gql';
import { isFilterEnabled } from "~/modules/catalog/category/config/FiltersConfig";
import { useFilters } from "~/modules/catalog/category/components/filters/useFilters";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: "FortytwoSearchPage",
  components: {
    CategoryPagination,
    SfSelect,
    LazyHydrate,
    SfHeading,
    DYDataHandler,
    CategoryNavbar,
    SearchWithEmpty,
    GA4AddRemoveCart,
    GA4SelectItem,
    CustomHead,
    CategoryEmptyResults: () =>
      import("~/modules/catalog/category/components/CategoryEmptyResults.vue"),
    CategoryProductGrid: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductGrid.vue"
      ),
    CategoryProductList: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductList.vue"
      ),
    CategoryFilters: () =>
      import(
        "~/modules/catalog/category/components/filters/CategoryFilters.vue"
      ),
    SkeletonLoader,
    DYProductWithEmpty,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  transition: "fade",
  setup() {
    const { addTags } = useCache();
    const isShowProducts = ref(false);
    // const minTermLen = ref(3);
    // const itemsPerPage = ref(48);
    // const currentPageData = ref(1);
    const { addItemToCart, isInCart, error: cartError } = useAddToCart();
    const resultFace = ref<UseFacetSearchResult>({ data: null, input: null });
    const attributelist = ref({ data: null });
    const { activeCategory, loadCategoryTree } = useTraverseCategory();
    const sortBy = ref<SortingModel>({ selected: "", options: [] });
    // const loading = ref(false);
    const uiHelpers = useUiHelpers();
    const term = ref("");
    const productContainerElement = ref<HTMLElement | null>(null);
    const products = ssrRef<ProductInterface[]>([]);
    const pagination = ref<Pagination>({});
    const route = useRoute();
    const checkdataready = ref(false);
    const filtertype = ref('search');
    const DYProduct = ref();
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const { app: { i18n } } = useContext();
    const { send: sendNotification } = useUiNotification();
    const { sendFacebookPixel } = useFtSendFacebookPixel();
    const cateProductDetails = ref({});
    const productSelected = ref({});
    const filters = ref<Aggregation[]>([]);
    const activeCategoryName = computed(() => activeCategory.value?.name ?? "");
    const activeCategoryNameGA4 = activeCategory.value?.name ?? "";
    const activecategorystore = useActiveCategoryStore();
    activecategorystore.$patch((state) => {
      state.activecategory = activeCategoryNameGA4;
    });
    const {
      selectedFilters,
    } = useFilters();
    const { cart } = useCart();
    const triggerAddRemoveCartTrack = ref(false);
    const triggerSelectItemTrack = ref(false);
    const selectItemEvent = async (eventData) => {
      productSelected.value = await eventData;
      triggerSelectItemTrack.value = true;
    };

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
      isFilterColumnOpen, } =
      useUiState();

      const {
      load: loadWishlist,
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();

    const { result, search, loading: loadingSearch } = useFacetSearch();

    const addingItemToCart = async (product) => {
      await addItemToCart(product);

      const promptNotification = () => {
        if (cartError.value?.addItem?.message) {
          sendNotification({
            id: Symbol('add_to_cart_updated'),
            message: cartError.value.addItem.message,
            type: 'danger',
            icon: '',
            persist: false,
            title: 'AddToCart Notification',
          });
        } else {
          sendNotification({
            id: Symbol('add_to_cart_updated'),
            message: i18n.t('{product} was added to your cart.', {product: product.product.name}) as string,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'AddToCart Notification',
          });
        }
      };

      if (product.product.type_id == 'simple') {
        promptNotification();
      } 
      else if (product.product.type_id == 'configurable') {
        if (product.canAddConfigItmToCart) {
          promptNotification();
        }
      }
    };

    const addItemToWishlist = async (product: Product) => {
      let message = '';
      if (isInWishlist({ product })) {
        await removeItemFromWishlist({ product });
        message = i18n.t('Item removed from wishlist successfully.') as string;
      } else {
        await addItemToWishlistBase({ product });
        message = i18n.t('Item added to wishlist successfully.') as string;
      }

      sendNotification({
        id: Symbol('wishlist_updated'),
        message: message,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Wishlist Notification',
      });
    };

    const addItemToWishlistLogin = () => {
      sendNotification({
        id: Symbol('wishlist_updated'),
        message: i18n.t('Oh no, we have a problem here! Remember to log in to add this item to your wishlist.') as string,
        type: 'warning',
        icon: '',
        persist: false,
        title: 'Wishlist Notification',
      });
    };

    const {
      query: { q, page },
    } = route.value;

    term.value = q.toString();

    const { query } = useApi();

    const isPriceLoaded = ref(false);

    const { fetch } = useFetch(async () => {
      if (!activeCategory.value) {
        loadCategoryTree();
      }

      const searchParam = ref({...uiHelpers.getFacetsFromURL()});

      const doProductFilterBySearchQueryResult = async () => {
        const productFilterBySearchQueryResult = await query<{ products: Products }>(GetProductFilterBySearchQuery, { search: term.value });
        const loadedFilters = productFilterBySearchQueryResult?.data?.products?.aggregations ?? [];
        filters.value = loadedFilters.filter((filter) =>
          isFilterEnabled(filter.attribute_code) && filter.attribute_code !== "category_uid"
        );

        const validFilters = [];
        if (Object.keys(searchParam.value.filters).length){
          filters.value.forEach((filter)=>{
            validFilters.push(filter.attribute_code);
          });

          // remove invalid param for search
          Object.keys(searchParam.value?.filters).forEach((filter) => {
            if(validFilters.indexOf(filter) === -1 && filter != 'q') {
              Reflect.deleteProperty(searchParam.value?.filters, filter);
            }
          });
        }
      }

      const isSearchParamEmpty = () => {
        const searchParamFilters = { ...searchParam.value.filters };
        delete searchParamFilters.q;
        return Object.keys(searchParamFilters).length === 0;
      }
      
      // when search param is not empty, await to remove invalid param before search product
      if (isSearchParamEmpty()){
         doProductFilterBySearchQueryResult();
      }
      else{
        await doProductFilterBySearchQueryResult();
      }
      await search(searchParam.value);

      products.value = facetGetters.getProducts(result.value) ?? [];
      pagination.value = facetGetters.getPagination(result.value);
      sortBy.value = facetGetters.getSortOptions(result.value);

      isPriceLoaded.value = true;
      isShowProducts.value = true;
    
      const searchTermTag = [{ prefix: CacheTagPrefix.View, value: `search-${Buffer.from(term.value).toString('base64')}` }];
      const viewSearchTag = [{ prefix: CacheTagPrefix.View, value: 'search' }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid,
      }));

      addTags([...searchTermTag, ...productTags, ...viewSearchTag]);
    });

    const fetchDYData = async () => {
      const selectType = ["[Search] Recommendations"];
      const productsku = [];
      const currentURL = window.location.href;

      await apidycall(selectType,"OTHER","Choose",productsku,[],currentURL,false);

      for (let campaignIndex = 0; campaignIndex < dyresultdata.value.length; campaignIndex++) {
        DYProduct.value = { 
          variationsId : dyresultdata.value[campaignIndex].variationsId,
          data : dyresultdata.value[campaignIndex].data, 
          decisionId : dyresultdata.value[campaignIndex].decisionId, 
          title:dyresultdata.value[campaignIndex].title, 
          itemsku:dyresultdata.value[campaignIndex].itemsku
        };
      };
    };

    onMounted(async () => {
      loadWishlist();
      if(process.client){
        fetchDYData();
      }
    
      // facabook pixel and conversions api
      await sendFacebookPixel('Search', q.toString(), q.toString());
      await sendFacebookPixel('PageView', '');
    });

    const goToPage = async (page: number) => {
      uiHelpers.changePage(page);
      productContainerElement.value.scrollIntoView();
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // })
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(1);
    };

    const onReloadProducts = () => {
      goToPage(1);
    };

    // @ts-ignore
    return {
      // page,
      resultFace,
      isShowProducts,
      isFilterColumnOpen,
      pagination,
      products,
      addingItemToCart,
      addItemToWishlist,
      addItemToWishlistLogin,
      isPriceLoaded,
      productContainerElement,
      onReloadProducts,
      doChangeItemsPerPage,
      goToPage,
      ...uiHelpers,
      attributelist,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      sortBy,
      term,
      isFilterSidebarOpen,
      toggleFilterSidebar,
      filtertype,
      DYProduct,
      activeCategoryName,
      isdataready : computed(()=> checkdataready.value ? true : false),
      triggerAddRemoveCartTrack,
      triggerSelectItemTrack,
      selectItemEvent,
      cateProductDetails,
      productSelected,
      cart,
      selectedFilters,
      filters,
      loadingSearch
    };
  },
  head() {
    const page = {
      title: `Search result for ${this.term}`,
    };
    return getMetaInfo(page);
  },
});
