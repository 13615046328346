import { useApi } from '~/composables/useApi';

export const useFtReviewScore= () => {
  const { query } = useApi();

  const getReviewScore:any = async (product_uid) =>{
    try {
      if (!product_uid) {
        return null;
      }
      const variables = { product_id: product_uid };
      const myGraphqlQuery = `
        query getreviewscore($product_id: String!){
          getreviewscore(product_id: $product_id) {
            review_total_score
            review_chart_score {
              one
              two
              three
              four
              five
            }
          }
        }
        `;  
      const { data } = await query(myGraphqlQuery, variables);

      return {
        data,
      };
      
    } catch (error) {
      console.error("Error fetching review score:", error);
      return null; 
    }
  }
  return {
    getReviewScore,
  }
}


export default useFtReviewScore;