









































































































































































import LazyHydrate from "vue-lazy-hydration";
import { SfSelect, SfHeading } from "@storefront-ui/vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  ssrRef,
  useFetch,
  useContext,
  watch
} from "@nuxtjs/composition-api";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import { usePageStore } from "~/stores/page";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import CategoryPagination from "~/modules/catalog/category/components/pagination/CategoryPagination.vue";
import { useCategory, useFacet, useUiHelpers, useUiState, useUiNotification } from "~/composables";
import { useAddToCart } from "~/helpers/cart/addToCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { usePrice } from "~/modules/catalog/pricing/usePrice";
import { useCategoryContent } from "~/modules/catalog/category/components/cms/useCategoryContent";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { getMetaInfo } from "~/helpers/getMetaInfo";

import CategoryNavbar from "~/modules/catalog/category/components/navbar/CategoryNavbar.vue";
import CategoryBreadcrumbs from "~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue";

import type { ProductInterface, Products, Aggregation } from "~/modules/GraphQL/types";
import type { SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import type { Pagination } from "~/composables/types";
import type { Product } from "~/modules/catalog/product/types";
import GA4AddRemoveCart from "~/modules/fortytwo/googleanalytics4/components/GA4AddRemoveCart.vue";
import GA4SelectItem from "~/modules/fortytwo/googleanalytics4/components/GA4SelectItem.vue";
import { useActiveCategoryStore } from "~/modules/fortytwo/category/stores/activecategory";
import { useCart } from "~/modules/checkout/composables/useCart";
import CategoryTopFilters  from "~/modules/catalog/category/components/filters/CategoryTopFilters.vue";
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';
import useDropDownFilter  from "~/modules/catalog/category/composables/useCategory/dropdownFilter";
import { useFilters } from "~/modules/catalog/category/components/filters/useFilters";
import { merge } from 'lodash-es';
import { isFilterEnabled } from "~/modules/catalog/category/config/FiltersConfig";
import { useApi } from '~/composables/useApi';
import GetProductFilterByCategoryQuery from '~/modules/catalog/category/components/filters/command/getProductFilterByCategory.gql';
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import DYDataHandler from "~/components/FortyTwo/homepage/DYRecommendation/DYDataHandler.vue";
import CategoryType from "~/dy/components/CategoryType.vue";
import BeautifulWithEmpty from "~/components/FortyTwo/homepage/BeautifulWithEmpty.vue";

export default defineComponent({
  name: "CategoryPage",
  components: {
    CategoryPagination,
    CategoryEmptyResults: () =>
      import("~/modules/catalog/category/components/CategoryEmptyResults.vue"),
    CategoryFilters: () =>
      import(
        "~/modules/catalog/category/components/filters/CategoryFilters.vue"
      ),
    CmsContent: () =>
      import("~/modules/catalog/category/components/cms/CmsContent.vue"),
    CategoryProductGrid: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductGrid.vue"
      ),
    CategoryProductList: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductList.vue"
      ),
    CategoryNavbar,
    CategoryBreadcrumbs,
    SfSelect,
    LazyHydrate,
    SfHeading,
    SkeletonLoader,
    GA4AddRemoveCart,
    GA4SelectItem,
    CategoryTopFilters,
    DYDataHandler,
    CategoryType,
    BeautifulWithEmpty,
  },
  props:[
    "routeData"
  ],
  transition: "fade",
  setup(props) {
    const { query } = useApi();
    const routeData = props.routeData;
    // const { routeData } = usePageStore();
    const { getContentData } = useCategoryContent();
    const { loadCategoryMeta } = useCategory();
    const { addTags } = useCache();
    const uiHelpers = useUiHelpers();
    const cmsContent = ref("");
    const isShowCms = ref(false);
    const isShowProducts = ref(false);
    const dropDownFilter = ref([]);
    const isShowRating = ref(false);
    const products = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: "", options: [] });
    const pagination = ref<Pagination>({});
    const filtertype = ref('category');
    const {
      selectedFilters,
    } = useFilters();
    const sharedFilters = ref(selectedFilters);

    const productContainerElement = ref<HTMLElement | null>(null);
    const categoryContainerElement =  ref<HTMLElement | null>(null);

    const { app: { i18n } } = useContext();
    const { send: sendNotification } = useUiNotification();
    const { sendFacebookPixel } = useFtSendFacebookPixel();

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
      isFilterColumnOpen,
    } = useUiState();
    const {
      load: loadWishlist,
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();
    const wishlistStore = useWishlistStore();
    const { result, search, loading: loadingSearch } = useFacet();
    const { addItemToCart, isInCart, error: cartError } = useAddToCart();
    const categoryMeta = ref(null);
    const categoryMinPrice = ref(0);
    const categoryMaxPrice = ref(1000);
    const filters = ref<Aggregation[]>([]);

    const addingItemToCart = async (product) => {
      await addItemToCart(product);

      const promptNotification = () => {
        if (cartError.value?.addItem?.message) {
          sendNotification({
            id: Symbol('add_to_cart_updated'),
            message: cartError.value.addItem.message,
            type: 'danger',
            icon: '',
            persist: false,
            title: 'AddToCart Notification',
          });
        } else {
          sendNotification({
            id: Symbol('add_to_cart_updated'),
            message: i18n.t('{product} was added to your cart.', {product: product.product.name}) as string,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'AddToCart Notification',
          });
        }
      };

      if (product.product.type_id == 'simple') {
        promptNotification();
      } 
      else if (product.product.type_id == 'configurable') {
        if (product.canAddConfigItmToCart) {
          promptNotification();
        }
      }
    };

    const addItemToWishlist = async (product: Product) => {
      let message = '';
      if (isInWishlist({ product })) {
        await removeItemFromWishlist({ product });
        message = i18n.t('Item removed from wishlist successfully.') as string;
      } else {
        await addItemToWishlistBase({ product });
        message = i18n.t('Item added to wishlist successfully.') as string;
      }

      sendNotification({
        id: Symbol('wishlist_updated'),
        message: message,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Wishlist Notification',
      });
    };

    const addItemToWishlistLogin = () => {
      sendNotification({
        id: Symbol('wishlist_updated'),
        message: i18n.t('Oh no, we have a problem here! Remember to log in to add this item to your wishlist.') as string,
        type: 'warning',
        icon: '',
        persist: false,
        title: 'Wishlist Notification',
      });
    };

    const { activeCategory, loadCategoryTree, categoryAncestors } = useTraverseCategory();
    const activeCategoryName = computed(() => activeCategory.value?.name ?? "");
    const activeCategoryNameGA4 = activeCategory.value?.name ?? "";
    const activecategorystore = useActiveCategoryStore();
    activecategorystore.$patch((state) => {
      state.activecategory = activeCategoryNameGA4;
    });
    const { cart } = useCart();
    const cateProductDetails = ref({});
    const productSelected = ref({});
    // to avoid duplicated event send to the GA4, while in category page, user delete or add item using sidebar cart, it will tigger 2 time event call due to sidebar cart and category page cart using the same component in own page and binding the same things which is cart quantity.
    const triggerAddRemoveCartTrack = ref(false);
    const triggerSelectItemTrack = ref(false);

    const selectItemEvent = async (eventData) => {
      productSelected.value = await eventData;
      triggerSelectItemTrack.value = true;
    };

    const updateSharedFilter = (eventData) => {
      sharedFilters.value = {};
      sharedFilters.value = merge({}, sharedFilters.value, eventData);
    };

    const categoryUid = routeData.uid;

    const categoryTitleTree = ref([]);

    const { fetch } = useFetch(async () => {
      if (!activeCategory.value) {
        loadCategoryTree();
      }
      
      const searchParam = ref({...uiHelpers.getFacetsFromURL(), category_uid: categoryUid });

      const doProductFilterByCategoryQueryResult = async () => {
        const productFilterByCategoryQueryResult = await query<{ products: Products }>(GetProductFilterByCategoryQuery, { categoryIdFilter: {eq: categoryUid} });
        const loadedFilters = productFilterByCategoryQueryResult?.data?.products?.aggregations ?? [];
        filters.value = loadedFilters.filter((filter) =>
          isFilterEnabled(filter.attribute_code) && filter.attribute_code !== "category_uid"
        );

        const validFilters = [];
        if (Object.keys(searchParam.value.filters).length){
          filters.value.forEach((filter)=>{
            validFilters.push(filter.attribute_code);
          });

          // remove invalid param for search
          Object.keys(searchParam.value?.filters).forEach((filter) => {
            if(validFilters.indexOf(filter) === -1) {
              Reflect.deleteProperty(searchParam.value?.filters, filter);
            }
          });
        }
      }

      const isSearchParamEmpty = () => {
        const searchParamFilters = { ...searchParam.value.filters };
        return Object.keys(searchParamFilters).length === 0;
      }

      // when search param is not empty, await to remove invalid param before search product
      if (isSearchParamEmpty()){
         doProductFilterByCategoryQueryResult();
      }
      else{
        await doProductFilterByCategoryQueryResult();
      }

      const [_, content, categoryMetaData] = await Promise.all([
        search(searchParam.value), // getfacetdata
        getContentData(categoryUid as string),
        loadCategoryMeta({ category_uid: routeData?.uid })
      ]);
      if (content?.dropdownFilter) {
        dropDownFilter.value = content?.dropdownFilter.split(","); 
      }

      if(content?.showrating){
        isShowRating.value = content?.showrating;
      }

      products.value = facetGetters.getProducts(result.value) ?? [];
      sortBy.value = facetGetters.getSortOptions(result.value);
      pagination.value = facetGetters.getPagination(result.value);

      isShowProducts.value = content.isShowProducts;
      categoryMeta.value = categoryMetaData;


      await categoryAncestors.value.slice().reverse().forEach(category => {
        categoryTitleTree.value.push(category.name);
      });
      categoryMeta.value.meta_title = categoryTitleTree.value.join(" - ");
      categoryMeta.value.og_title = categoryMeta.value.name;
      cmsContent.value = content?.cmsBlock?.content ?? "";
      isShowCms.value = content.isShowCms;
      
      // cache only when category has products
      if (products.value && products.value.length) {
        const tags = [{ prefix: CacheTagPrefix.Category, value: routeData.uid }];
        const viewCategoryTags = [{ prefix: CacheTagPrefix.View, value: 'category' }];

        const productTags = products.value.map((product) => ({
          prefix: CacheTagPrefix.Product,
          value: product.uid,
        }));
        addTags([...tags, ...productTags, ...viewCategoryTags]);
      }
    });

    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const DYProduct = ref();
    const DYProduct2 = ref();
    const isbeautifulready = ref(false);
    const isDYDisplay = ref(false);

    watch(
      activeCategoryName,
      (newVal) => {
        if (newVal !== null && newVal !== ""){
          fetchDYData();
        }
      },
    );
    
    const fetchDYData = async () => {
        const selectType = ["[Category Page] Bottom Recommendations","[Category Page] Bottom Type"];
        const productsku = [activeCategoryName.value];
        const currentURL = window.location.href;
        if(activeCategoryName.value != ''){
          await apidycall(selectType,"CATEGORY","Choose",productsku,[],currentURL,false);
          if(dyresultdata.value?.length){
            for (let campaignIndex = 0; campaignIndex < dyresultdata.value.length; campaignIndex++) {
              if(dyresultdata.value[campaignIndex].campaigntype == "[Category Page] Bottom Recommendations" && dyresultdata.value[campaignIndex].data.length > 0){
                DYProduct.value = { 
                  variationsId : dyresultdata.value[campaignIndex].variationsId,
                  data : dyresultdata.value[campaignIndex].data, 
                  decisionId : dyresultdata.value[campaignIndex].decisionId, 
                  title:dyresultdata.value[campaignIndex].title, 
                  itemsku:dyresultdata.value[campaignIndex].itemsku
                };
              }else if(dyresultdata.value[campaignIndex].campaigntype == "[Category Page] Bottom Type" && dyresultdata.value[campaignIndex].data.length > 0){
                DYProduct2.value = { 
                  data : dyresultdata.value[campaignIndex].data, 
                  decisionId : dyresultdata.value[campaignIndex].decisionId, 
                };
              }
            }
          }
        } 
      };

    const isPriceLoaded = ref(false);

    onMounted(async () => {
      if (!wishlistStore?.wishlist?.id) {
        loadWishlist();
      }
      const { getPricesBySku } = usePrice();
      if (products.value.length > 0) {
        const skus = products.value.map((item) => item.sku);
        const priceData = await getPricesBySku(
          skus,
          pagination.value.itemsPerPage
        );
        products.value = products.value.map((product) => {
          const priceRange = priceData.items.find(
            (item) => item.sku === product.sku
          )?.price_range;

          if (priceRange) {
            return {
              ...product,
              price_range: priceRange,
            };
          }

          return { ...product };
        });      
      }

      isPriceLoaded.value = true;

      sendFacebookPixel('PageView', '');
      sendFacebookPixel('ViewCategory', activeCategoryName.value, activeCategoryName.value);
      fetchDYData();
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page);
      categoryContainerElement.value.scrollIntoView();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
      productContainerElement.value.scrollIntoView();
    };

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      isFilterColumnOpen,
      addingItemToCart,
      addItemToWishlist,
      addItemToWishlistLogin,
      pagination,
      products,
      sortBy,
      isShowCms,
      isShowProducts,
      cmsContent,
      activeCategoryName,
      doChangeItemsPerPage,
      productContainerElement,
      categoryContainerElement,
      categoryMeta,
      onReloadProducts,
      goToPage,
      cateProductDetails,
      cart,
      triggerAddRemoveCartTrack,
      productSelected,
      triggerSelectItemTrack,
      selectItemEvent,
      filtertype,
      dropDownFilter,
      isShowRating,
      sharedFilters,
      updateSharedFilter,
      DYProduct,
      DYProduct2,
      isDYDisplay : computed(()=> DYProduct.value ? true : false),
      isbeautifulready : computed(()=> DYProduct2.value ? true : false),
      loadingSearch,
      categoryMinPrice,
      categoryMaxPrice,
      filters,
    };
  },
  head() {
    return getMetaInfo(this.categoryMeta);
  },
});
